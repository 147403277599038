type ENV = "dev" | "prod" | "demo";

export const currentEnv: string = "prod";

const env: string = currentEnv;

export const Env = env;

const API_DEV_URL = "https://easy-market-api.withvolkeno.com";
const API_DEMO_URL = "https://easy-market-api.withvolkeno.com";
const API_PROD_URL = "https://api.easymarket.sn";

const VENDEUR_APIKEY_DEMO =
  "6eae18ea6df5a30e52b9a75e42619d109589de28c01c76c0610a3893b226a5df";
const VENDEUR_APIKEY_PROD =
  "1fa786f32580cc12acb5e2ccfa533104b88ca0667f21d7c97f34c3fa1471e7ff";

export const APP_URL_DEV = "https://easy-market.volkeno.com/";
export const APP_URL_DEMO = "https://easy-market.volkeno.com/";
export const APP_URL_PROD = "https://torobee.easymarket.sn/";

function processApiUrl() {
  if (env === "prod") return API_PROD_URL;
  if (env === "demo") return API_DEMO_URL;
  return API_DEV_URL;
}

function processAppUrl() {
  if (env === "prod") return APP_URL_PROD;
  if (env === "demo") return APP_URL_DEMO;
  return APP_URL_DEV;
}

function processApikey() {
  if (env === "prod") return VENDEUR_APIKEY_PROD;
  if (env === "demo") return VENDEUR_APIKEY_DEMO;
  return VENDEUR_APIKEY_DEMO;
}

export const ApiBaseUrl = processApiUrl();
export const AppBaseUrl = processAppUrl();

export const VendeurApikey = processApikey();

export const GoogleApikey = "AIzaSyD0klc1PQS8QK--Be3rF3i8kW_idy04AVQ";

export const IntechApikey = "554ACA96-F050-442D-8E29-BEE77FAE821A";

export const GoogleClientId =
  "252665710140-5euq0h9htj81foss4ur1gllbbl8qtig9.apps.googleusercontent.com";
